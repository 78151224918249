<template>
  <div id="stages">
    <div class="title">{{ stage }} app's stages</div>

    <ol>
      <li v-for="_stage in stages" :key="_stage">{{ _stage }}</li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "Stages",

  props: {
    stage: {
      type: String,
      required: true,
    },

    stages: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#stages {
  .title {
    padding-bottom: 8px;
    font-weight: 600;
    text-transform: capitalize;
    border-bottom: 1px solid var(--divider-color);
  }

  li {
    margin-bottom: 4px;
  }
}
</style>
