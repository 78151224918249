<template>
  <BaseScrollbar>
    <table>
      <!-- thead  -->

      <thead>
        <tr>
          <th>Sub Modules</th>
          <th>Beta App's Stage</th>
          <th>Final App's Stage</th>
          <th>API's Status</th>
          <th>Current Stage Owner</th>
          <th>Expected Finish Date</th>
          <th>Priority</th>
          <th class="action-column">
            <BaseActionButton
              is-flat
              icon="eva-plus"
              color="secondary"
              no-border
              @click="addSubModule"
            />
          </th>
        </tr>
      </thead>

      <!-- ...  -->

      <!-- tbody  -->

      <tbody>
        <!-- no sub modules  -->

        <tr v-if="!module.subModules.length">
          <td colspan="8" class="text-center q-py-sm">Not started yet</td>
        </tr>

        <!-- ...  -->

        <!-- sub modules  -->

        <tr v-for="_module in module.subModules" :key="_module.id">
          <!-- name  -->

          <th>{{ _module.name }}</th>

          <!-- ...  -->

          <!-- current beta stage  -->

          <td>
            <Datum
              icon="eva-cube-outline"
              color="orange"
              :label="_module.betaStage"
              :options="_betaStages"
              @click="
                (value) => updateSubModule(_module.id, 'betaStage', value)
              "
            />
          </td>

          <!-- ...  -->

          <!-- current final stage  -->

          <td>
            <Datum
              icon="eva-cube-outline"
              color="green"
              :label="_module.finalStage"
              :options="_finalStages"
              @click="
                (value) => updateSubModule(_module.id, 'finalStage', value)
              "
            />
          </td>

          <!-- ...  -->

          <!-- api status  -->

          <td>
            <Datum
              icon="eva-flag-outline"
              :color="statusColor(_module.apiStatus)"
              :label="_module.apiStatus"
              :options="statuses"
              @click="
                (value) => updateSubModule(_module.id, 'apiStatus', value)
              "
            />
          </td>

          <!-- ...  -->

          <!-- current stage owner  -->

          <td>
            <Datum
              icon="eva-person-outline"
              :label="_module.currentStageOwner"
              :options="owners"
              @click="
                (value) =>
                  updateSubModule(_module.id, 'currentStageOwner', value)
              "
            />
          </td>

          <!-- ...  -->

          <!-- expected finish date  -->

          <td>
            <Datum
              icon="eva-clock-outline"
              color="orange"
              :label="_module.expectedFinishDate"
            >
              <q-menu>
                <q-date
                  :value="_module.expectedFinishDate"
                  minimal
                  mask="YYYY-MM-DD"
                  @input="
                    (value) =>
                      updateSubModule(_module.id, 'expectedFinishDate', value)
                  "
                />
              </q-menu>
            </Datum>
          </td>

          <!-- ...  -->

          <!-- priority  -->

          <td>
            <Datum
              icon="eva-flag-outline"
              :color="priorityColor(_module.priority)"
              :label="_module.priority"
              :options="priorities"
              @click="(value) => updateSubModule(_module.id, 'priority', value)"
            />
          </td>

          <!-- ...  -->

          <!-- remove module  -->

          <td class="action-column">
            <BaseActionButton
              is-flat
              icon="eva-close"
              color="red"
              @click="removeSubModule(_module.id)"
            />
          </td>

          <!-- ...  -->
        </tr>

        <!-- ...  -->
      </tbody>

      <!-- ...  -->
    </table>
  </BaseScrollbar>
</template>

<script>
import Datum from "./Datum.vue";

export default {
  name: "Module",

  components: { Datum },

  props: {
    module: {
      type: Object,
      required: true,
    },

    betaStages: {
      type: Array,
      required: true,
    },

    finalStages: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      owners: [
        {
          id: this.$nano.id(),
          name: "Arasu",
          color: "secondary",
        },
        {
          id: this.$nano.id(),
          name: "Arputharaj",
          color: "secondary",
        },
        {
          id: this.$nano.id(),
          name: "Charles Vinoth",
          color: "secondary",
        },
        {
          id: this.$nano.id(),
          name: "Raja",
          color: "secondary",
        },
        {
          id: this.$nano.id(),
          name: "Thanaselvi",
          color: "secondary",
        },
      ],
      priorities: [
        {
          id: this.$nano.id(),
          name: "High",
          color: "red",
        },
        {
          id: this.$nano.id(),
          name: "Normal",
          color: "green",
        },
        {
          id: this.$nano.id(),
          name: "Low",
          color: "blue",
        },
      ],
      statuses: [
        {
          id: this.$nano.id(),
          name: "Not Started",
          color: "blue",
        },
        {
          id: this.$nano.id(),
          name: "Pending",
          color: "red",
        },
        {
          id: this.$nano.id(),
          name: "Completed",
          color: "green",
        },
      ],
    };
  },

  computed: {
    _betaStages() {
      return this.processStages(this.betaStages);
    },

    _finalStages() {
      return this.processStages(this.finalStages);
    },
  },

  methods: {
    processStages(stages) {
      const _stages = [
        {
          id: this.$nano.id(),
          name: "Not Started",
        },
      ];

      stages.forEach((_, idx) =>
        _stages.push({
          id: this.$nano.id(),
          name: `Stage - ${idx + 1}`,
        })
      );

      _stages.push({
        id: this.$nano.id(),
        name: "Completed",
      });

      return _stages;
    },

    statusColor(status) {
      return this.statuses.find((_status) => _status.name === status).color;
    },

    statusIcon(status) {
      return this.statuses.find((_status) => _status.name === status).icon;
    },

    priorityColor(priority) {
      return this.priorities.find((_priority) => _priority.name === priority)
        .color;
    },

    updateSubModule(subModuleId, key, value) {
      this.$emit("updateSubModule", subModuleId, key, value);
    },

    addSubModule() {
      this.$emit("addSubModule");
    },

    removeSubModule(subModuleId) {
      this.$emit("removeSubModule", subModuleId);
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  table-layout: fixed;
  border-collapse: collapse;
  text-align: left;
  min-width: calc(100vw - 272px);
  white-space: nowrap;

  th,
  td {
    @extend .text-sm;
    border: 1px solid var(--divider-color);
    padding: 4px 8px;
  }

  th {
    font-weight: 600;
    text-transform: capitalize;
  }

  thead {
    th {
      color: var(--primary);
    }
  }

  tbody {
    th {
      color: var(--secondary);
    }
  }

  .action-column {
    width: 48px;
  }
}
</style>
