<template>
  <div id="datum">
    <BaseIcon :name="icon" size="20px" :color="color" />

    <div class="label">{{ label }}</div>

    <BaseIcon name="eva-chevron-down" />

    <q-menu fit content-class="q-py-sm">
      <ListItem
        v-for="option in options"
        :key="option.id"
        v-close-popup
        :icon="icon"
        :label="option.name"
        :is-selected="label === option.name"
        @click="$emit('click', option.name)"
      />
    </q-menu>

    <slot></slot>
  </div>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";

import colorValidator from "@/helpers/color-validator.js";

export default {
  name: "Datum",

  components: { ListItem },

  props: {
    icon: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      default: () => [],
    },

    color: {
      type: String,
      default: "secondary",
      validator: colorValidator,
    },

    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#datum {
  display: flex;

  &:hover {
    cursor: pointer;
  }

  .label {
    text-transform: capitalize;
    margin: 0px 8px;
    flex: 1;
  }
}
</style>
