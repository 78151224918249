<template>
  <div id="modules">
    <!-- platform -->

    <div class="platforms">
      <BaseIcon name="eva-cube-outline" />

      <div class="label">{{ selectedPlatform }} modules</div>

      <BaseIcon name="eva-chevron-down" />

      <q-menu fit content-class="q-py-sm">
        <ListItem
          v-for="platform in platforms"
          :key="platform"
          v-close-popup
          icon="eva-cube-outline"
          :label="platform"
          :is-selected="selectedPlatform === platform"
          class="text-capitalize"
          @click="selectPlatform(platform)"
        />
      </q-menu>
    </div>

    <!-- ... -->

    <!-- modules -->

    <div
      v-for="module in _modules"
      :key="module.id"
      class="module"
      :class="{ 'is-selected': module.id === selectedModule.id }"
      @click="selectModule(module)"
    >
      {{ module.name }}
    </div>

    <!-- ... -->

    <!-- add module -->

    <div class="add-module">
      <BaseButton
        icon="eva-plus"
        label="add module"
        color="secondary"
        class="full-width"
        @click="addModule"
      />
    </div>

    <!-- ... -->
  </div>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "Modules",

  components: { ListItem },

  props: {
    modules: {
      type: Object,
      required: true,
    },

    selectedPlatform: {
      type: String,
      required: true,
    },

    selectedModule: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      editMode: false,
    };
  },

  computed: {
    platforms() {
      return Object.keys(this.modules);
    },

    _modules() {
      return this.modules[this.selectedPlatform];
    },
  },

  methods: {
    selectPlatform(platform) {
      this.$emit("selectPlatform", platform);
    },

    selectModule(module) {
      this.$emit("selectModule", module);
    },

    addModule() {
      this.$emit("addModule");
    },
  },
};
</script>

<style lang="scss" scoped>
#modules {
  width: 224px;
  border-right: 1px solid var(--divider-color);
  padding-right: 16px;

  .platforms {
    display: flex;
    padding: 16px;
    margin: -16px -16px 8px;
    border-bottom: 1px solid var(--divider-color);

    .label {
      flex: 1;
      text-transform: capitalize;
      margin-left: 16px;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--hover-bg-color);
    }
  }

  .module {
    padding: 8px 16px;
    margin: 0px -16px;
    text-transform: capitalize;

    &:hover {
      cursor: pointer;
      background-color: var(--hover-bg-color);
    }

    &.is-selected {
      color: var(--secondary);
      font-weight: 500;
    }
  }

  .add-module {
    border-top: 1px solid var(--divider-color);
    padding-top: 16px;
    margin-top: 8px;
  }
}
</style>
