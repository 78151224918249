<template>
  <Modal :value="value" width="540px" has-footer @input="close">
    <template #title>
      add {{ type === "SUB_MODULE" ? "sub module" : "module" }}
    </template>

    <template #default>
      <div id="new-module">
        <div class="q-my-lg">
          Please enter a name for the
          {{ type === "SUB_MODULE" ? "sub module" : "module" }}
        </div>

        <ValidationObserver ref="form">
          <ValidationProvider
            v-slot="{ errors }"
            name="name"
            :rules="{ required: true }"
          >
            <TextField
              v-model="name"
              label="name"
              is-mandatory
              :error="errors[0]"
              class="q-mb-lg"
            />
          </ValidationProvider>
        </ValidationObserver>
      </div>
    </template>

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="cancel" />
      <BaseButton label="save" @click="save" />
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/common/popup/Modal.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";

export default {
  name: "NewModule",

  components: { Modal, ValidationObserver, ValidationProvider, TextField },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      name: "",
    };
  },

  methods: {
    close() {
      this.$emit("input", false);
    },

    cancel() {
      this.name = "";
      this.close();
    },

    async save() {
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }

      this.$emit("save", this.name);
      this.cancel();
    },
  },
};
</script>

<style lang="scss" scoped>
#new-module {
}
</style>
