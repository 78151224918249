<template>
  <div id="dev-status">
    <div class="header">
      <div class="title">Development Status</div>

      <q-space />

      <BaseButton icon="eva-save-outline" label="save" @click="saveDevStatus" />
    </div>

    <q-spinner v-if="isLoading" color="primary" size="48px" class="spinner" />

    <div v-else class="content">
      <Modules
        :modules="modules"
        :selected-platform="selectedPlatform"
        :selected-module="selectedModule"
        @selectPlatform="selectPlatform"
        @selectModule="selectModule"
        @addModule="newModule"
      />

      <div class="col q-pl-md">
        <div class="title-1 q-pb-md">{{ selectedModule.name }}</div>

        <!-- stages  -->

        <div class="row q-pb-md">
          <Stages stage="beta" :stages="betaStages" class="col" />

          <Stages stage="final" :stages="finalStages" class="col" />
        </div>

        <!-- ...  -->

        <Module
          :module="selectedModule"
          :beta-stages="betaStages"
          :final-stages="finalStages"
          @addSubModule="newModule('SUB_MODULE')"
          @updateSubModule="updateSubModule"
          @removeSubModule="removeSubModule"
        />
      </div>
    </div>

    <NewModule
      v-model="showNewModule"
      :type="newModuleType"
      @save="addModule"
    />
  </div>
</template>

<script>
import { devStatus } from "@/api/factory.js";

import Modules from "./components/Modules.vue";
import Stages from "./components/Stages.vue";
import Module from "./components/Module.vue";
import NewModule from "./components/NewModule.vue";

export default {
  name: "DevStatus",

  components: { Modules, Stages, Module, NewModule },

  data() {
    return {
      isLoading: false,
      modules: {},
      selectedPlatform: "WEB",
      selectedModule: {},
      betaStages: [
        "Research, Analysis & Planning",
        "Prototype Design",
        "Functionality Development & API Integration",
        "Code review & Refactoring",
        "Demo, Discussion & Reiteration",
      ],
      finalStages: [
        "Final design based on Prototype",
        "Development Improvisation & Optimization",
        "Testing and Quality Analysis",
        "Deployment & Support",
      ],
      showNewModule: false,
      newModuleType: "MODULE",
    };
  },

  watch: {
    selectedPlatform() {
      this.selectedModule = this.modules[this.selectedPlatform][0];
    },
  },

  created() {
    this.getDevStatus();
  },

  methods: {
    async getDevStatus() {
      this.isLoading = true;

      const { error, data } = await devStatus.getStatus();

      if (!error) {
        this.modules = data;
        console.log(data);
        this.selectedModule = this.modules[this.selectedPlatform][0];
      }

      this.isLoading = false;
    },

    selectPlatform(platform) {
      this.selectedPlatform = platform;
    },

    selectModule(module) {
      this.selectedModule = module;
    },

    newModule(type = "MODULE") {
      this.newModuleType = type;
      this.showNewModule = true;
    },

    addModule(name) {
      if (this.newModuleType === "MODULE") {
        this.modules[this.selectedPlatform].push({
          id: this.$nano.id(),
          name,
          subModules: [],
        });
      }

      if (this.newModuleType === "SUB_MODULE") {
        const subModule = {
          id: this.$nano.id(),
          name,
          betaStage: "Not Started",
          finalStage: "Not Started",
          apiStatus: "Not Started",
          currentStageOwner: "-",
          expectedFinishDate: "-",
          priority: "Normal",
        };

        const platform = this.selectedPlatform;
        const moduleIdx = this.getModuleIndex();
        this.modules[platform][moduleIdx].subModules.push(subModule);
      }
    },

    getModuleIndex() {
      return this.modules[this.selectedPlatform].findIndex(
        (module) => module.id === this.selectedModule.id
      );
    },

    getSubModuleIndex(subModuleId) {
      const moduleIdx = this.getModuleIndex();

      return this.modules[this.selectedPlatform][
        moduleIdx
      ].subModules.findIndex((module) => module.id === subModuleId);
    },

    updateSubModule(subModuleId, key, value) {
      const platform = this.selectedPlatform;
      const moduleIdx = this.getModuleIndex();
      const subModuleIdx = this.getSubModuleIndex(subModuleId);

      this.modules[platform][moduleIdx].subModules[subModuleIdx][key] = value;
    },

    removeSubModule(subModuleId) {
      const platform = this.selectedPlatform;
      const moduleIdx = this.getModuleIndex();
      const subModuleIdx = this.getSubModuleIndex(subModuleId);

      this.modules[platform][moduleIdx].subModules.splice(subModuleIdx, 1);
    },

    async saveDevStatus() {
      this.isLoading = true;

      const payload = { content: JSON.stringify(this.modules) };
      const { error } = await devStatus.saveStatus(payload);

      if (!error) {
        this.getDevStatus();
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#dev-status {
  background-color: var(--component-bg-color);
  padding: 16px;

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--divider-color);
  }

  .title {
    @extend .text-2xl;
    font-weight: 500;
  }

  .spinner {
    position: fixed;
    top: calc(50vh - 24px);
    left: calc(50vw - 24px);
  }

  .content {
    display: flex;
    min-height: calc(100vh - 85px);
    padding-top: 16px;
  }
}
</style>
